var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DAV-iconpicker"
  }, [_c('Spinner', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    attrs: {
      "propsPartial": true
    }
  }), _c('div', {
    staticClass: "frmBlock__colorPicker"
  }, [_c('button', {
    staticClass: "selectImage__icon",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.openPopupDetail
    }
  }, [_c('span'), _c('div', {
    staticClass: "mask-icon",
    style: {
      'background-color': _vm.option.value.color,
      '-webkit-mask-image': "url(".concat(_vm.selectIcon.url, ")")
    }
  })]), _c('button', {
    staticClass: "frmBlock__link",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.showColorPicker(_vm.option.componentOption.id, $event);
      }
    }
  }), _c('div', {
    staticClass: "selectImage--color"
  }, [_c('span', {
    style: {
      'background-color': _vm.option.value.color
    }
  })])]), _c('div', {
    staticClass: "DAV-colorpicker"
  }, [_c('ColorPicker', {
    ref: _vm.option.componentOption.id,
    attrs: {
      "value": _vm.option.value.color
    },
    on: {
      "input": function input($event) {
        return _vm.updateColorPicker($event.hex8);
      }
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.colorpickerLayer[_vm.option.componentOption.id],
      expression: "colorpickerLayer[option.componentOption.id]"
    }],
    staticClass: "DAV-colorpicker__preview"
  }, [_c('p', [_vm._v("이전 적용한 색상")]), _c('button', {
    staticClass: "frmBlock__colorPicker",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.updateColorPicker(_vm.preColor);
      }
    }
  }, [_c('div', {
    staticClass: "selectImage--color"
  }, [_c('span', {
    style: {
      'background-color': _vm.preColor
    }
  })])])]), _c('button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.colorpickerLayer[_vm.option.componentOption.id],
      expression: "colorpickerLayer[option.componentOption.id]"
    }],
    staticClass: "DAV-colorpicker--close",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.hideColorPicker(_vm.option.componentOption.id, $event);
      }
    }
  }, [_c('span', [_vm._v("색상 선택 접기")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }