<template>
  <div class="DAV-iconpicker">
    <Spinner v-show="isLoading" :propsPartial="true" />
    <div class="frmBlock__colorPicker">
      <button type="button" class="selectImage__icon" @click="openPopupDetail">
        <span></span>
        <div
          class="mask-icon"
          :style="{
            'background-color': option.value.color,
            '-webkit-mask-image': `url(${selectIcon.url})`
          }"
        />
      </button>
      <button
        type="button"
        class="frmBlock__link"
        @click="showColorPicker(option.componentOption.id, $event)"
      ></button>
      <div class="selectImage--color">
        <span
          :style="{
            'background-color': option.value.color
          }"
        ></span>
      </div>
    </div>
    <div class="DAV-colorpicker">
      <ColorPicker
        :value="option.value.color"
        :ref="option.componentOption.id"
        @input="updateColorPicker($event.hex8)"
      />
      <div
        class="DAV-colorpicker__preview"
        v-show="colorpickerLayer[option.componentOption.id]"
      >
        <p>이전 적용한 색상</p>
        <button
          type="button"
          class="frmBlock__colorPicker"
          @click="updateColorPicker(preColor)"
        >
          <div class="selectImage--color">
            <span
              :style="{
                'background-color': preColor
              }"
            ></span>
          </div>
        </button>
      </div>
      <button
        type="button"
        class="DAV-colorpicker--close"
        v-show="colorpickerLayer[option.componentOption.id]"
        @click="hideColorPicker(option.componentOption.id, $event)"
      >
        <span>색상 선택 접기</span>
      </button>
    </div>
  </div>
</template>

<script>
import {Chrome} from "vue-color";

export default {
  props: {
    option: {
      type: Object
    }
  },
  data() {
    return {
      colorpickerLayer: {},
      iconFiles: [],
      selectIcon: {},
      preColor: "",
      isLoading: false
    };
  },
  mounted() {
    this.preColor = this.option.value.color;
    this.iconList();
  },
  created() {
    this.$EventBus.$on(`UpdateIcon${this.option.id}`, id => {
      this.updateIcon(id);
      this.selectIcon = this.iconFiles?.filter(i => i.id === id)[0];
    });
  },
  methods: {
    // 아이콘 목록
    iconList() {
      const params = {
        id: this.option.value.group
      };

      this.isLoading = true;
      this.$store
        .dispatch("ModuleEditor/actIconList", params)
        .then(response => {
          const data = response.data;
          this.iconFiles = data.iconFiles;
          const select = parseInt(this.option.value.img.slice(1));
          this.selectIcon = this.iconFiles?.filter(i => i.id === select)[0];
          this.isLoading = false;
        });
    },
    // 옵션 color Update
    updateColorPicker(value) {
      this.$emit(
        "updateOption",
        this.option.componentOption.key,
        this.option.componentOption.properties[2].key,
        value
      );
    },
    // 옵션 icon Update
    updateIcon(id) {
      this.$emit(
        "updateOption",
        this.option.componentOption.key,
        this.option.componentOption.properties[1].key,
        `i${id}`
      );
    },
    // colorpicker show
    showColorPicker(key, e) {
      e.target.style.display = "none";

      let el = this.$refs[key].$el;
      el.querySelector(".vc-chrome-saturation-wrap").style.display = "block";
      el.querySelector(".vc-chrome-body .vc-chrome-controls").style.display =
        "flex";

      this.$set(this.colorpickerLayer, key, true);
    },
    // colorpicker hide
    hideColorPicker(key, e) {
      e.currentTarget.parentNode.previousSibling.querySelector(
        ".frmBlock__link"
      ).style.display = "block";

      let el = this.$refs[key].$el;
      el.querySelector(".vc-chrome-saturation-wrap").style.display = "none";
      el.querySelector(".vc-chrome-body .vc-chrome-controls").style.display =
        "none";

      this.$set(this.colorpickerLayer, key, false);
    },
    // 아이콘 팝업 이동
    openPopupDetail() {
      if (!this.iconFiles.length) {
        return;
      }
      this.$store.commit("ModuleEditor/setPopup", {
        page: "ComponentIcon",
        value: {
          iconFiles: this.iconFiles,
          seleceId: this.selectIcon.id,
          color: this.option.value.color
        },
        option: this.option
      });
    }
  },
  components: {
    ColorPicker: Chrome,
    Spinner: () => import("@/components/basis/spinner.vue")
  }
};
</script>

<style lang="scss">
.DAV-iconpicker .vc-chrome-body .vc-chrome-fields-wrap {
  left: 57px !important;
}
.DAV-iconpicker .selectImage__icon {
  position: absolute !important;
  top: 14px;
}
.DAV-iconpicker .selectImage--color {
  left: 57px !important;
}
.DAV-iconpicker .frmBlock__link {
  left: 57px !important;
}
/* ColorPicker Custom */
.vc-chrome {
  font-family: "SpoqaHanSansNeo", "Malgun Gothic", sans-serif !important;
  width: 100% !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  box-sizing: border-box !important;
  padding-top: 62px;
}
.vc-chrome-saturation-wrap {
  display: none;
  margin-top: 8px;
  padding-bottom: 45% !important;
  border-radius: 8px !important;
}
.vc-chrome-body {
  padding: 0 !important;

  .vc-chrome-controls {
    display: none;
    margin-top: 8px;
  }
  .vc-chrome-fields-wrap {
    position: absolute;
    top: -2px;
    right: 1px;
    left: 1px;
  }
}
.vc-chrome-fields {
  margin-left: 0 !important;

  .vc-chrome-field {
    padding-left: 0 !important;
  }

  &:nth-child(1) {
    /* HEX */
    margin-right: 8px !important;

    .vc-input__label {
      margin-left: 46px;
    }
    .vc-input__input {
      padding-left: 46px;
    }
  }
  &:nth-child(2) {
    /* RGBA */
    display: flex !important;

    .vc-chrome-field {
      margin-left: -1px;

      &:nth-child(1) .vc-input__input {
        border-radius: 8px 0 0 8px !important;
      }
      &:nth-child(2) .vc-input__input,
      &:nth-child(3) .vc-input__input {
        border-radius: 0 !important;
      }
      &:nth-child(4) .vc-input__input {
        border-radius: 0 8px 8px 0 !important;
      }
    }
  }
}
.vc-chrome-toggle-btn {
  display: none !important;
}
.vc-input__label {
  position: absolute;
  top: -26px;
  right: 0;
  left: 0;
  color: #606060 !important;
  font-size: 12px !important;
}
.vc-input__input {
  height: 48px !important;
  background: #ffffff !important;
  border: 1px solid #d8dae5 !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
@media screen and (max-width: 1023px) and (min-width: 600px) {
  .vc-chrome-saturation-wrap {
    padding-bottom: 35% !important;
  }
}
.DAV-colorpicker {
  position: relative;
  box-sizing: border-box;
  border-radius: 8px;

  &--close {
    display: block;
    width: 100%;
    margin-top: 16px;
    margin-bottom: -4px;
    padding-top: 12px;
    border-top: 1px solid #f1f1f1;

    span {
      padding-right: 16px;
      background-image: url($path + "bul_arr_top_gray.svg");
      background-repeat: no-repeat;
      background-position: 100% 50%;
      color: #8d8d8d;
      font-weight: 400;
      font-size: 14px;
    }
  }
  &__preview {
    margin-top: 22px;

    p {
      color: #606060;
      font-weight: 500;
      font-size: 14px;
    }
    .frmBlock__colorPicker {
      margin-top: 8px;

      .selectImage--color {
        position: relative;
        top: 0;
        left: 0 !important;
      }
    }
  }
}
.frmBlock__link {
  z-index: 1;
  position: absolute;
  top: 14px;
  left: 0;
  right: 0;
  height: 48px;
}
.frmBlock__colorPicker {
  z-index: 1;
  position: relative;
  display: flex;

  .frmBlock__textField:hover {
    border-color: #d8dae5;
    box-shadow: none;
  }
  .frmBlock__textField:focus {
    border-color: #d8dae5;
  }
}
.selectImage__icon {
  position: relative;
  width: 48px;
  height: 48px;
  margin-right: 8px;
  background-color: #f4f7ff;
  border: 1px solid #d8dae5;
  box-sizing: border-box;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .mask-icon {
    width: 21px;
    height: 21px;
    -webkit-mask-size: 21px 21px;
  }

  span {
    position: absolute;
    top: -10px;
    right: -4px;
    width: 20px;
    height: 20px;
    background-image: url($path + "ico_edit.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}
.selectImage--color {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 14px;
  left: 0;
  width: 48px;
  height: 48px;
  background-color: #ffffff;
  border: 1px solid #d8dae5;
  box-sizing: border-box;
  border-radius: 8px;
  background-image: url($path + "bg_hex.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;

  span {
    width: 22px;
    height: 22px;
    border-radius: 22px;
  }
}
</style>
